const company = [
  [
    "集团领导",
    "总裁:1人",
    "副总裁1",
    "副总裁2",
    "副总裁3",
    "副总裁4",
    "副总裁5",
  ],
  [
    "行政部",
    "部门总经理:1人",
    "总经理助理:1人",
    "行政经理:1人",
    "行政职员1",
    "行政职员2",
    "行政职员3",
  ],
  [
    "人力资源部",
    "部门总经理:1人",
    "总经理助理:1人",
    "人事经理:1人",
    "人事职员1",
    "人事职员2",
    "人事职员3",
  ],
  [
    "财务部",
    "部门总经理:1人",
    "总经理助理:1人",
    "财务经理1",
    "财务经理2",
    "财务经理3",
    "财务职员1",
    "财务职员2",
    "财务职员3",
    "财务职员4",
    "财务职员5",
    "财务职员6",
    "财务职员7",
    "财务职员8",
    "财务职员9",
    "财务职员10",
  ],
  [
    "工程部",
    "部门总经理:1人",
    "总经理助理:1人",
    "工程经理1",
    "工程经理2",
    "工程经理3",
    "工程职员1",
    "工程职员2",
    "工程职员3",
    "工程职员4",
  ],
  [
    "企业管理部",
    "部门总经理:1人",
    "总经理助理:1人",
    "工程经理1",
    "工程经理2",
    "工程经理3",
    "工程职员1",
    "工程职员2",
    "工程职员3",
    "工程职员4",
  ],
  [
    "技术研发部",
    "部门总经理:1人",
    "总经理助理:1人",
    "工程经理1",
    "工程经理2",
    "工程经理3",
    "工程职员1",
    "工程职员2",
    "工程职员3",
    "工程职员4",
  ],
  [
    "采购部",
    "部门总经理:1人",
    "总经理助理:1人",
    "工程经理1",
    "工程经理2",
    "工程经理3",
    "工程职员1",
    "工程职员2",
    "工程职员3",
    "工程职员4",
  ],
  [
    "审计部",
    "部门总经理:1人",
    "总经理助理:1人",
    "工程经理1",
    "工程经理2",
    "工程经理3",
    "工程职员1",
    "工程职员2",
    "工程职员3",
    "工程职员4",
  ],
];

const outside = [
  [
    "西藏XX电厂",
    ["经理室", "总经理:1人", "副总:1人", "总助:1人"],
    [
      "综合办公室",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "水处理车间",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "生产技术部",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "运行值班",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
  ],
  [
    "安徽XX电厂",
    ["经理室", "总经理:1人", "副总:1人", "总助:1人"],
    [
      "综合办公室",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "水处理车间",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "生产技术部",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "运行值班",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
  ],
  [
    "云南XX电厂",
    ["经理室", "总经理:1人", "副总:1人", "总助:1人"],
    [
      "综合办公室",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "水处理车间",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "生产技术部",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "运行值班",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
  ],
  [
    "河南XX电厂",
    ["经理室", "总经理:1人", "副总:1人", "总助:1人"],
    [
      "综合办公室",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "水处理车间",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "生产技术部",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "运行值班",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
  ],
  [
    "四川XX电厂",
    ["经理室", "总经理:1人", "副总:1人", "总助:1人"],
    [
      "综合办公室",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "水处理车间",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "生产技术部",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "运行值班",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
  ],
  [
    "浙江XX电厂",
    ["经理室", "总经理:1人", "副总:1人", "总助:1人"],
    [
      "综合办公室",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "水处理车间",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "生产技术部",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "运行值班",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
  ],
  [
    "江苏XX电厂",
    ["经理室", "总经理:1人", "副总:1人", "总助:1人"],
    [
      "综合办公室",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "水处理车间",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "生产技术部",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "运行值班",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
  ],
  [
    "陕西XX电厂",
    ["经理室", "总经理:1人", "副总:1人", "总助:1人"],
    [
      "综合办公室",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "水处理车间",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "生产技术部",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "运行值班",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
  ],
  [
    "黑龙江XX电厂",
    ["经理室", "总经理:1人", "副总:1人", "总助:1人"],
    [
      "综合办公室",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "水处理车间",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "生产技术部",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "运行值班",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
  ],
  [
    "江西XX电厂",
    ["经理室", "总经理:1人", "副总:1人", "总助:1人"],
    [
      "综合办公室",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "水处理车间",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "生产技术部",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
    [
      "运行值班",
      "办公室主任:1人",
      "职员1",
      "职员2",
      "职员3",
      "职员4",
      "职员5",
      "职员6",
      "职员7",
      "职员8",
    ],
  ],
];

const items = [
  {
    key: "1",
    label: "姓名",
    children: "对应姓名",
  },
  {
    key: "2",
    label: "性别",
    children: "对应性别",
  },
  {
    key: "3",
    label: "年龄",
    children: "对应年龄",
  },
  {
    key: "4",
    label: "学历",
    children: "对应学历",
  },
  {
    key: "5",
    label: "经验",
    children: "对应经验",
    span: 2,
  },
  {
    key: "6",
    label: "兴趣与爱好",
    children: "对应兴趣与爱好",
    span: 3,
  },
  {
    key: "7",
    label: "其他各种个人信息",
    children: "对应其他各种个人信息",
    span: 3,
  },
];

export default { company, outside, items };
